import _ from 'lodash'
import React from 'react'
import VideoCsr from '../video'
import CarouselCsr from '../carousel-csr'
import Gallery from '../gallery'

const index = ({
  item,
}) => {
  let display
  if (item) {
    switch (true) {
      case item.video !== false:
        display = <VideoCsr videoSrcURL={item.video} videoTitle={item.title} />
        break
      case item.images_type === 'gallery':
        display = <Gallery data={item.images} />
        break
      case item.images_type === 'carousel':
        display = <CarouselCsr data={item.images} />
        break
      default:
        display = null
        break
    }
  }
  return (
    <React.Fragment>{display}</React.Fragment>
  )
}

export default index
