import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import "./csr-styles.scss"
import { Seo, BannerCTA, Spinner, GradientBanner } from "../../components"
import { HeaderCrs, BlogsRelated, CrsBody } from "./components"

const CrsView = ({ data, loading }) => {
  const { t } = useTranslation()
  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <React.Fragment>
      <div className="csr__container">
        <Seo title="CSR" />
        <div className="csr__box">
          {_.map(_.get(data, "acf.rows"), (layouts, idx) => {
            let layout
            switch (true) {
              case layouts.acf_fc_layout === "hero_two_images_copy":
                layout = (
                  <div key={idx} className="">
                    <HeaderCrs
                      title={_.get(data, "title")}
                      colorTitle={_.get(data, "acf.colored_title")}
                      data={layouts}
                      t={t}
                    />
                  </div>
                )
                break
              case layouts.acf_fc_layout === "csr":
                layout = (
                  <div key={idx} className="">
                    <CrsBody data={layouts} t={t} />
                  </div>
                )
                break

              case layouts.acf_fc_layout === "related_articles":
                layout = (
                  <div key={idx} className="crs__articles">
                    <BlogsRelated data={layouts} t={t} />
                  </div>
                )
                break

              default:
                break
            }
            return layout
          })}
        </div>
      </div>
      <GradientBanner />
    </React.Fragment>
  )
}
export default CrsView
