import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  Carousel,
  CardListVertical,
} from '../../../../components'

const CsrRelated = ({ data }) => {
  const { t } = useTranslation()
  return (
    <div className='insightsDetail__carousel'>

      <div className="">
        <div className="carousel__tagline">
          <h2>{t('csr.related')}</h2>
        </div>
        <div>
          <Carousel
            data={data}
            sToShow={3}
            sToScroll={3}
            arrows={true}
          >
            {data && data.map((insight, i) => <div key={i} className="cardTeam__box">
              <CardListVertical
                data={insight}
              />
            </div>)}
          </Carousel>
        </div>
      </div>

    </div>
  )
}

export default CsrRelated
