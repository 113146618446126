import React from 'react'
import _ from 'lodash'

const Gallery = ({
  data,
}) => (
  <div className="csr__display--gallery row">
    {
      _.map(data, (d, id) => (
        <div className="col-12 col-md-6" key={id}>
          <div className="frame">
            <img src={d.image.url} alt={d.image.alt} />

          </div>
        </div>

      ))
    }

  </div>
)

export default Gallery
