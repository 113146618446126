import _ from 'lodash'
import React from 'react'
import { Router } from '@reach/router'
import CrsScreen from '../screens/csr'
import CrsDetailScreen from '../screens/csr-detail'

const CrsPage = (props) => {
  const path = _.get(props, 'pageContext.locale') === 'en' ? '/csr/' : `${_.get(props, 'pageContext.locale')}/csr/`
  return (
    <Router>
      <CrsDetailScreen {...props} path={`${path}:slug`} />
      <CrsScreen {...props} path={`${path}`} />
    </Router>
  )
}

export default CrsPage

