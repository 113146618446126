import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Carousel,
  CardListVertical,
} from '../../../../components'

const BlogRelated = ({ data }) => {
  const { t } = useTranslation()
  return (
    <div className='insightsDetail__carousel'>

      <div className="">
        <div className="carousel__tagline">
          <h2>{t('insightsDetail.related')}</h2>
        </div>
        <div>
          <Carousel
            data={data.list}
            sToShow={3}
            sToScroll={3}
            arrows={true}
          >
            {data && data.list.map((news, i) => <div key={i} className="cardTeam__box">
              <CardListVertical
                data={news}
              />
            </div>)}
          </Carousel>
        </div>
      </div>

    </div>
  )
}

export default BlogRelated
